import { routes } from "@/services/account.service";
import useSWR from "swr";
import { Account } from "@/types/models/Account";
import { fetcherV2 } from "@/services/api";

export function useCharities() {
  const { data, error, mutate, isLoading } = useSWR(
    () => [routes.list, {}],
    fetcherV2<Account[]>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    charities: data ?? [],
    refresh: mutate,
    isLoading,
    isError: error,
  };
}
