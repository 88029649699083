import { API_V2 } from "@/config/api";
import { RecurringPlan } from "@/types/models/RecurringPlan";
import apiRequest from "./api";

export const routes = {
  get: (id: string | number) => `${API_V2}/recurring/${id}`,
  list: `${API_V2}/recurring`,
  stop: (id: string | number) => `${API_V2}/recurring/${id}/stop`,
};

export interface QueryRecurringPlans {
  status?: {
    in?: RecurringPlan["status"][];
    notIn?: RecurringPlan["status"][];
  };
  interval?: "daily" | "weekly" | "monthly" | "yearly";
  page: number;
  limit: number;
  sortBy: "createdAt" | "grossAmount";
  sortDirection: "ASC" | "DESC";
  accountId?: string;
}

export interface QueryRecurringResponse {
  result: RecurringPlan[];
  total: number;
  page: number;
  pages: number;
}

export interface UpdatePlanRequest {}

export const stopPlan = (id: string | number, data: UpdatePlanRequest) =>
  apiRequest<RecurringPlan>(routes.stop(id), {
    method: "DELETE",
    data,
  });
