import { FaHandHoldingHeart } from "react-icons/fa";
import { IconType } from "react-icons";
import { BsBox2Heart, BsPersonHeart } from "react-icons/bs";
import { AiOutlineDashboard } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";

export interface Route {
  name: string;
  icon: IconType;
  path: string;
  hidden: boolean;
  children?: Route[];
}

interface RouteGroup {
  label: string;
  routes: Route[];
}

export const appRoutes: RouteGroup[] = [
  {
    label: "",
    routes: [
      {
        name: "Dashboard",
        icon: AiOutlineDashboard,
        path: "/",
        hidden: false,
      },
      {
        name: "Donations",
        icon: BsBox2Heart,
        path: "/donations",
        hidden: false,
        children: [
          {
            name: "Donations",
            icon: FaHandHoldingHeart,
            path: "/donations/[donationId]",
            hidden: true,
          },
        ],
      },
    ],
  },
];

export function findRouteByPath(path: string): Route | null {
  function traverseRoutes(route: Route): Route | null {
    if (route.path === path) {
      return route;
    }

    if (route.children) {
      for (const child of route.children) {
        const foundRoute = traverseRoutes(child);
        if (foundRoute) {
          return foundRoute;
        }
      }
    }

    return null;
  }

  for (const route of appRoutes) {
    for (const subRoute of route.routes) {
      const foundRoute = traverseRoutes(subRoute);
      if (foundRoute) {
        return foundRoute;
      }
    }
  }

  return null;
}
