import { API_V2 } from "@/config/api";
import { RoundupPlans } from "@/types/models/RoundupPlan";
import { Transaction } from "@/types/models/Transactions";
import apiRequest from "./api";

export const routes = {
  get: (id: string | number) => `${API_V2}/roundup/${id}`,
  list: `${API_V2}/roundup`,
  update: (id: string | number) => `${API_V2}/roundup/${id}`,
  transactions: (id: string | number) => `${API_V2}/roundup/${id}/transactions`,
  delete: (id: string | number) => `${API_V2}/roundup/${id}`,
};

export interface UpdatePlanRequest
  extends Partial<
    Pick<RoundupPlans, "status" | "monthlyLimit" | "roundupMode">
  > {}

export const updatePlan = (id: string | number, data: UpdatePlanRequest) =>
  apiRequest<RoundupPlans>(routes.update(id), {
    method: "POST",
    data,
  });

export const deletePlan = (id: string | number) =>
  apiRequest<void>(routes.delete(id), {
    method: "DELETE",
  });

export interface TransactionsRequest {
  periodId: string | number | undefined;
  page: number;
  limit: number;
  sortBy: "createdAt";
  sortDirection: "ASC" | "DESC";
}

export interface TransactionsResponse {
  result: Transaction[];
  total: number;
  page: number;
  pages: number;
}

export interface QueryRoundupPlans {
  status?: {
    in?: RoundupPlans["status"][];
    notIn?: RoundupPlans["status"][];
  };
  page: number;
  limit: number;
  sortBy: "createdAt" | "total";
  sortDirection: "ASC" | "DESC";
  accountId?: string;
}

export interface QueryRoundupResponse {
  result: RoundupPlans[];
  total: number;
  page: number;
  pages: number;
}
