import { API, API_V2 } from "@/config/api";
import { Donations } from "@/types/models/Donations";

export const routes = {
  list: `${API_V2}/donations`,
  get: (id: string | number) => `${API_V2}/donations/${id}`,
};

export interface DonationsRequest {
  page: number;
  limit: number;
  sortBy: "createdAt" | "grossAmount";
  sortDirection: "ASC" | "DESC";
  accountId?: string;
}

export interface DonationsResponse {
  result: Donations[];
  total: number;
  page: number;
  pages: number;
}
