/* eslint-disable @next/next/no-img-element */
import Page from "@/containers/Page";
import { Row } from "@/styles/layout";
import Image from "next/image";

import { motion } from "framer-motion";
import { usePageInfo } from "@/hooks/usePageInfo";
import { Account } from "@/types/models/Account";
import Loader from "@/components/Loader";
import { useCharities } from "@/hooks/useCharities";
import { useMemo } from "react";
import Link from "next/link";
import { preload } from "swr";
import { routes as charitiesRoute } from "@/services/account.service";
import { routes as donationsRoute } from "@/services/donations.service";
import { routes as recurringRoutes } from "@/services/recurring.service";
import { routes as roundupsRoutes } from "@/services/roundup.service";
import { FiLink2 } from "react-icons/fi";
import { useMe } from "@/hooks/useMe";
import { fetcherV2 } from "@/services/api";
import { getMediaCdnUrl } from "@/lib/cdn";

const Charity = ({ account }: { account: Account }) => {
  const prefetch = () => {
    preload([charitiesRoute.get(account.id), {}], fetcherV2);
    preload(
      [
        recurringRoutes.list,
        {
          accountId: account.id,
          page: 0,
          limit: 10,
          sortBy: "createdAt",
          sortDirection: "DESC",
        },
      ],
      fetcherV2
    );
    preload(
      [
        roundupsRoutes.list,
        {
          accountId: account.id,
          page: 0,
          limit: 10,
          sortBy: "createdAt",
          sortDirection: "DESC",
        },
      ],
      fetcherV2
    );
    preload(
      [
        donationsRoute.list,
        {
          accountId: account.id,
          page: 0,
          limit: 10,
          sortBy: "createdAt",
          sortDirection: "DESC",
          status: "paid",
        },
      ],
      fetcherV2
    );
  };

  const logo = useMemo(() => {
    return getMediaCdnUrl(account.id, "logo", account.branding?.logoUrl);
  }, [account]);

  const banner = useMemo(() => {
    if (!account) return null;
    return getMediaCdnUrl(
      account.id,
      "banner",
      account.branding?.imageUrl,
      "large"
    );
  }, [account]);

  return (
    <motion.div
      whileHover={{ scale: 0.98 }}
      className="flex flex-col items-stretch bg-white shadow-lg cursor-pointer"
      onMouseEnter={prefetch}
    >
      <Link href={`/charity/${account.id}`}>
        <div className="bg-white rounded-sm">
          <div className="h-[244px] w-full relative z-0">
            {banner && (
              <Image
                src={banner}
                alt={`${account.name} banner image`}
                className="object-cover"
                fill
              />
            )}
          </div>
          {logo && (
            <img
              src={logo}
              alt={`${account.name} logo`}
              className="ml-3 h-[62px] max-w-xs object-contain object-left bg-white p-3 shadow-md rounded-sm -mt-[44px] z-10 relative w-auto"
            />
          )}
          <div className="px-5 py-5 flex flex-col items-start space-y-2">
            <div className="text-2xl font-semibold">{account.name}</div>
            <div className="flex items-center space-x-8 text-gray-500">
              <div className="flex items-center space-x-2 hover:text-pink-700">
                <FiLink2 size={16} />
                <a href={account.website} target="_blank">
                  <div className="text-xs ">{account.website}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default function Home() {
  const { user } = useMe();
  const firstName = useMemo(() => {
    return user?.firstName;
  }, [user]);
  const routeInfo = usePageInfo();
  const { charities, isLoading } = useCharities();
  return (
    <Page title={`${routeInfo.name} | GoGive`}>
      <div className="container">
        <div className="p-6 bg-white shadow-md mb-8">
          <div className="text-2xl font-bold mb-2">{`Hi ${firstName},`}</div>
          <div className="text-sm font-normal">
            Click on a charity to see your donations history and manage your
            regular donation plans.
          </div>
        </div>
        {isLoading && (
          <Row justifyContent={"center"}>
            <Loader size={24} />
          </Row>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
          {charities.map((account) => (
            <Charity key={account.id} account={account} />
          ))}
        </div>
      </div>
    </Page>
  );
}
